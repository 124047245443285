import { Routes } from '@angular/router';

export const moduleRoutes: Routes = [
    { 
        path: 'home', 
        // loadChildren: 'src/app/modules/home/home.module#HomeModule'
        loadChildren: () => import('../../modules/home/home.module').then(module => module.HomeModule)
    },
    {
        path: 'gallery',
        loadChildren: () => import('../../modules/gallery/gallery.module').then(module => module.GalleryModule)
    },
    { 
        path: 'about', 
        loadChildren: () => import('../../modules/about/about.module').then(module => module.AboutModule) 
    },
    {   path: 'contact',
        loadChildren: () => import('../../modules/contact/contact.module').then(module => module.ContactModule)
    }
];